//** INFRASTRUCTURE **//
function MapInfrastructure(map) {
    let root = this,
        objectManager;

    // Получение координат городов и показ на карте
    root.showTowns = function (towns) {
        let geocoder,
            placemarks = [],
            TownIconContentLayout = ymaps.templateLayoutFactory.createClass(
                '<p style="color: #FFFFFF;">$[properties.iconContent]</p>'
            );

        towns.forEach(function (town, index) {
            geocoder = ymaps.geocode(town, {results: 1});

            geocoder.then(
                function (res) {
                    let townCoordinates = res.geoObjects.get(0).geometry.getCoordinates();
                    placemarks.push({
                        type: 'Feature',
                        id: index,
                        geometry: {
                            type: 'Point',
                            coordinates: townCoordinates
                        },
                        properties: {
                            hintContent: town,
                            balloonContentHeader: 'Город:',
                            balloonContent: town,
                            iconContent: index + 1
                        },
                        options: {
                            iconLayout: 'default#imageWithContent',
                            iconImageHref: 'build/site/images/icon_map.png',
                            iconImageSize: [26, 38],
                            iconContentOffset: [7, 7],
                            iconImageOffset: [-15, -38],
                            iconContentLayout: TownIconContentLayout
                        }

                    });

                    objectManager = new ymaps.ObjectManager();
                    objectManager.add(placemarks);
                    map.geoObjects.add(objectManager);
                }
            );
        });
    };

    root.Map = map;

    return root;
}
//** INFRASTRUCTURE END **//

$(document).ready(function () {
    let Infrastructure,
        $townsContainer = $('.towns'),
        towns = [];

    $('.towns__link', $townsContainer).each(function (index, element) {
        towns.push($(element).text());
    });

    function infrastructureReady() {
        let infrastructureMap  = new ymaps.Map('towns-map', {
                center: [55.755819, 37.617644],
                zoom: 5,
                behaviors: ['default', 'scrollZoom'],
                type:  'yandex#map',
                controls: [],
            },{
                suppressMapOpenBlock: 0,
                maxZoom: 17
            });

        Infrastructure = new MapInfrastructure(infrastructureMap);
        Infrastructure.showTowns(towns);
    }

    ymaps.ready(infrastructureReady);

    $('.js_burger-menu').on('click', function () {
        $(this).siblings('.header__content').addClass('header__content_state--open');
        $('body').addClass('menu-opened');
    });
    $('.js_menu-close').on('click', function () {
        $(this).parent().removeClass('header__content_state--open');
        $('body').removeClass('menu-opened');
    });

    let $page = $('html, body');
    $('.js_scroll-link').on('click', function() {
        $page.animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 400);
        return false;
    });

});